import React from "react";
import '../../Styles/slider.css'
import Image from "react-bootstrap/Image";

const Slider = () => {
    return (
        <div>
            <div class="Image-container">

                <div class="Image-slide-container">

                    <div class="slide-image-div">
                        <Image src={require("../../Images/review (1).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/review (2).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/review (3).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/review (4).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/review (5).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (1).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (2).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (3).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (4).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (5).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (6).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (7).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (8).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (9).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (10).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (12).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (13).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (14).jpg")} fluid />
                    </div>
            {/* Images Copy */}

            <div class="slide-image-div">
                        <Image src={require("../../Images/review (1).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/review (2).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/review (3).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/review (4).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/review (5).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (1).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (2).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (3).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (4).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (5).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (6).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (7).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (8).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (9).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (10).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (12).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (13).jpg")} fluid />
                    </div>
                    <div class="slide-image-div">
                        <Image src={require("../../Images/coustomer_Image (14).jpg")} fluid />
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Slider;