import React, { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from "react-bootstrap/Image";
import Aos from "aos";
import 'aos/dist/aos.css';


const Gallery = () => {

    useEffect(() => {
        Aos.init({ duration: 900 });
    }, []);

    return (
        <div>
            <Container>
                <h3 className="text-center mt-5" >This Gallery consists of Parking and our Room Conditions and their Amenities</h3>
                <p className="text-center" >(These Images are Real Images of Our Homestay)</p>
                <Row className="d-flex justify-content-around mt-5 mb-5">
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (1).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (2).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (3).jpg')} fluid rounded />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-around mt-5 mb-5">
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (4).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (5).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (6).jpg')} fluid rounded />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-around mt-5 mb-5">
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (7).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (8).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (9).jpg')} fluid rounded />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-around mt-5 mb-5">
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (10).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (11).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (12).jpg')} fluid rounded />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-around mt-5 mb-5">
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (13).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (14).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (15).jpg')} fluid rounded />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-around mt-5 mb-5">
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (16).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (17).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (18).jpg')} fluid rounded />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-around mt-5 mb-5">
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (19).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (20).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (21).jpg')} fluid rounded />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-around mt-5 mb-5">
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (22).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (42).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (24).jpg')} fluid rounded />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-around mt-5 mb-5">
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (25).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (26).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (27).jpg')} fluid rounded />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-around mt-5 mb-5">
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (28).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (29).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (30).jpg')} fluid rounded />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-around mt-5 mb-5">
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (43).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (32).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (33).jpg')} fluid rounded />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-around mt-5 mb-5">
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (34).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (35).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (36).jpg')} fluid rounded />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-around mt-5 mb-5">
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (37).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (38).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (39).jpg')} fluid rounded />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-around mt-5 mb-5">
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (44).jpg')} fluid rounded />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-around mt-5 mb-5">
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (23).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (31).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (40).jpg')} fluid rounded />
                    </Col>
                    <Col md={3} data-aos="zoom-in">
                        <Image alt="..." src={require('../../Images/Img (41).jpg')} fluid rounded />
                    </Col>
                </Row>

            </Container>
        </div>
    )

}

export default Gallery